import React from "react"
import sal from "sal.js"
import "../../../node_modules/sal.js/dist/sal.css"
import TransitionLink from "../TransitionLink"
// import Process from "../AboutPage/sections/process.old"
import Welcome from "./sections/welcome"

import svg from "../../assets/images/icons/data.js"
import content from "../../content/home"
import * as styles from "./home.module.css"
import Icon from "../../assets/images/1.js"

export default function IndexPage() {
  React.useEffect(() => {
    let pos = 25
    sal()

    pos = window.scrollY
    const nav = document.getElementById("s1")
    let height = nav.clientHeight
    let width = nav.clientWidth
    console.log(width + "x" + height)
    nav.style.maxHeight = height = "px"
    nav.style.width = width = "px"

    const handleScroll = event => {
      console.log("window.scrollY", window.scrollY)
      console.log("pos", pos)
      if (window.scrollY > pos) {
        nav.style.opacity = "0"
        nav.style.maxHeight = "0"
      }
    }

    function delayed() {
      setTimeout(handleScroll, 3000)
    }

    window.addEventListener("scroll", handleScroll, { once: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div>
      <div className={styles.wrapper}>
        {/* Container for the hero image and Subheadings/Areas */}
        <section id="s1" className={`${styles.section}`}>
          <div className={styles.row}>
            <aside className={`${styles.heroSlider} ${styles.imgContainer}`}>
              <div className={styles.home}>
                <img src={`${content.image}`} alt="hero slider" />
              </div>
            </aside>
            <aside className={styles.whatWeDoContainer}>
              <div className={styles.row}>
                {/* individual area cards */}
                {content.areas.map((area, index) => (
                  <div
                    className={styles.cols}
                    id={`area-${index + 1}`}
                    key={`area-${index + 1}`}
                  >
                    <div className={styles.row}>
                      <div className={styles.col3}>
                        <img src={`${area.svg}`} alt="" />
                      </div>
                      <div className={styles.innerText}>
                        <h2 className={styles.headline}>{`${area.title}`}</h2>
                        <p>{`${area.description}`}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </aside>
          </div>
        </section>

        <section>
          <Welcome />
          {/* <Process/> */}
          {/* {content.details.map((detail, index) => (
            <div className={styles.section} key={index}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.imgContainer}>
                    <div className={styles.topImg}>
                      <img src={`${detail.image}`} alt="img" />
                    </div>
                  </div>
                </div>

                <div className={styles.col}>
                  <div className={styles.fullText}>
                    <h2>{`${detail.title}`}</h2>
                    <span
                      className={styles.subFull}
                    >{`${detail.subtitle}`}</span>
                    <p>{`${detail.description}`}</p>
                    <TransitionLink
                      cover
                      direction="up"
                      bg="#fff3e3"
                      to={`${detail.link}`}
                    >
                      <span className={styles.viewMore}>
                        {`${detail.linkText}`}
                      </span>
                    </TransitionLink>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </section>
      </div>
    </div>
  )
}
