// extracted by mini-css-extract-plugin
export var area = "welcome-module--area--6f2d7";
export var areaTitle = "welcome-module--areaTitle--a44cf";
export var areaWrap = "welcome-module--areaWrap--bbe86";
export var bannerWrap = "welcome-module--bannerWrap--8e08e";
export var evenSlide = "welcome-module--evenSlide--9c405";
export var evenTextWrap = "welcome-module--evenTextWrap--aa294";
export var imgCategory = "welcome-module--imgCategory--64c05";
export var oddSlide = "welcome-module--oddSlide--f6c71";
export var oddTextWrap = "welcome-module--oddTextWrap--8758a";
export var slideImage = "welcome-module--slideImage--f3e9c";
export var slideWrap = "welcome-module--slideWrap--c40d1";
export var wrapper = "welcome-module--wrapper--298d7";