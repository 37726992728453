export default {
  image:
    "https://res.cloudinary.com/metrodesign/image/upload/v1598193196/metro-flooring/Kitchen/Marble/Marble_Tile_1_wvhu8l.jpg",
  whatWeDo: {
    title: "What we do",
    subtitle: "We install and remodel the kitchen and bath of your dreams",
  },
  services: {
    serviceZero: {
      image:
        "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_320/v1598193242/metro-flooring/Kitchen/Sunrise%20Kitchen/Sunrise_Kitchen_2_cakmn2.jpg",
      title: "Interior Design",
      description: "We upgrade and embellish your kitchen",
    },
    serviceOne: {
      image:
        "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_320/v1598193242/metro-flooring/Kitchen/Sunrise%20Kitchen/Sunrise_Kitchen_2_cakmn2.jpg",
      title: "Kitchen Remodeling",
      description: "We upgrade and embellish your kitchen",
    },
    serviceTwo: {
      image:
        "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_320/v1598193157/metro-flooring/Bathroom/Marble/Bathroom_Marble_1.jpg",
      title: "Bathroom Remodeling",
      description: "Your bathroom deserves a fresh new look",
    },
    serviceThree: {
      image:
        "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_320/v1598193186/metro-flooring/Closet/closet-slide-1_dchvz2_nrddik.png",
      title: "Custom Closets",
      description: "Let's build your tailored closets",
    },
  },
  areas: [
    {
      svg: "/icons/indesign.png",
      title: "INTERIOR DESIGN",
      description: "We upgrade and embellish your kitchen",
    },
    {
      svg: "/icons/indesign.png",
      title: "KITCHEN REMODELING",
      description: "We upgrade and embellish your kitchen",
    },
    {
      svg: "/icons/indesign.png",
      title: "BATHROOM REMODELING",
      description: "Your bathroom deserves a fresh new look",
    },
    {
      svg: "/icons/indesign.png",
      title: "CUSTOM CLOSETS",
      description: "Let's build your tailored closets",
    },
  ],
  details: [
    {
      title: "1Full Kitchen Remodel",
      subtitle: "Handcrafted cabin with highest quality",
      description:
        "Here to make sure you are in love with your new kitchen. We ensure the design and budget is well within what you are looking for!",
      link: "/portfolio",
      linkText: "Projects1",
      image:
        "https://penroseatthegrand.com/static/acd2067b5dcb90d0453eb4598e1c36ea/a41d1/Home_Carousel1_Residence_02.jpg",
    },
    {
      title: "2Full Kitchen Remodel",
      subtitle: "Handcrafted cabin with highest quality",
      description:
        "Here to make sure you are in love with your new kitchen. We ensure the design and budget is well within what you are looking for!",
      link: "/about",
      linkText: "Projects2",
      image:
        "https://dailyjaguar.com/wp-content/uploads/2021/05/grey-modern-sofa-1.jpg",
    },
    {
      title: "3Full Kitchen Remodel",
      subtitle: "Handcrafted cabin with highest quality",
      description:
        "Here to make sure you are in love with your new kitchen. We ensure the design and budget is well within what you are looking for!",
      link: "/home",
      linkText: "Projects3",
      image:
        "https://www.luxxu.net/blog/wp-content/uploads/2021/03/Top-20-Interior-Designers-in-Riyadh-1024x768-1.jpeg",
    },
  ],
}
