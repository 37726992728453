import React from "react"

import Layout from "../components/Layout"
import HomePage from "../components/HomePage"

export default function IndexPage() {
  return (
      <section>
        <HomePage />
      </section>
  )
}
