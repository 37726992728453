export default {
  images: [
    { 
      svg: './1.svg'
    },
    {  
     svg: './1.old.svg.svg'
    },
    {
      svg: './1.wrong.svg'
    }
  ]
  
}