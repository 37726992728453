import React, { Component } from "react"
import * as styles from "./welcome.module.css"
import content from "../../../content/home"

const areas = content.details

export default class Welcome extends Component {
  render() {
    let status
    let odd = index => {
      if (index % 2 == 0) {
        status = true
        return styles.evenSlide
      } else {
        status = false
        return styles.oddSlide
      }
    }

    return (
      <div className={styles.wrapper}>
        <section className={styles.section}>
          <article className={styles.bannerWrap}>
            <div className={styles.banner}>
              <h2>Welcome To Metro Flooring</h2>
              <div className={styles.subText}>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
                  facere animi accusamus ratione! Voluptatum accusamus pariatur
                  distinctio ad earum expedita dicta.
                </p>
              </div>
            </div>
          </article>
          {areas.map((detail, index) => (
            <article className={styles.slideWrap} key={index}>
              <aside className={odd(index)}>
                <div className={styles.slideImage} id={`image ${index + 1}`}>
                  <img src={detail.image} alt="slide" />
                </div>
                <div className={styles.imgCategory}>
                  <div
                    className={
                      status ? styles.evenTextWrap : styles.oddTextWrap
                    }
                  >
                    {detail.title}
                  </div>
                </div>
              </aside>
              <aside className={styles.areaWrap}>
                <div className={styles.area}>
                  <div className={styles.areaTitle}>
                    <h3>{detail.subtitle}</h3>
                  </div>
                  <div className={styles.areaText}>
                    <p>{detail.description}</p>
                    {/* <input type="button" /> */}
                  </div>
                </div>
              </aside>
            </article>
          ))}
        </section>
      </div>
    )
  }
}
